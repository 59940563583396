import './style/main.scss'
import keys from './globals/services/keys'
import scripts from "./globals/resources/scripts";
import vueInstance from "./globals/resources/vueInstance";
import RouteWatcher from "./globals/constructors/Utils/RouteWatcher";
import EventBus from "./globals/constructors/Utils/EventBus";


(async function () {
  await keys
    .check()
    .then(() => {
      window.EventBus = new EventBus;
      scripts.init()
        .then(() => {
          vueInstance.init()
          RouteWatcher.initObserver()
        })
    })
    .catch((error) => {
      console.log(error)
    })
}())





