import {body} from "../constants";
import sourceOfTruth from "../../state-management";
import LoopdeskMain from "../../LoopdeskMain";

export default {

  createDOMElement: (nodeName = 'DIV', classNames = [], text = null, id = null) => {
    const newElement = document.createElement(nodeName)
    classNames.forEach(c => newElement.classList.add(c))
    if (id) newElement.id = id
    if (text) newElement.innerText = text
    return newElement
  },


  init: function () {
    const id = 'loopdesk'
    const loopdeskElement = this.createDOMElement('DIV', ['loopdesk'], '', id)
    const destroy = this.destroy
    body.appendChild(loopdeskElement)
    const vm = new Vue({
      el: '#' + id,
      data: sourceOfTruth,
      components: {LoopdeskMain},
      template: `
      <section class="ld-section" :class="[getCurrentTheme()]">
        <LoopdeskMain />
      </section>`,
      methods: {
        leaveLoopdesk() {
          destroy(vm)
        },
        getCurrentTheme() {
          const storedTheme = localStorage.getItem('loopdesk.theme')
          if (storedTheme === 'theme--dark' || storedTheme === 'theme--light') return storedTheme
          return 'theme--light'
        },
        addWindowResizeListener() {
          window.addEventListener('resize', (e) => {
            sourceOfTruth.setMobileView(window.innerWidth <= 960)
          })
        }
      },
      async mounted() {
        this.addWindowResizeListener()
        window.EventBus.on('leaveLoopdesk', () => this.leaveLoopdesk())
        console.log("LOOPDESK MOUNTED")
      }
    });
  },


  destroy: function (vm) {
    const loopdeskSection = document.querySelector('section.ld-section')
    vm.$destroy()
    body.removeChild(loopdeskSection)
    sourceOfTruth.state.project.remove()
    sourceOfTruth.state.feedbacks = []
    localStorage.removeItem('loopdesk.key')
    localStorage.removeItem('loopdesk.plan')
    localStorage.removeItem('loopdesk.feedbacker_role')
    localStorage.removeItem('loopdesk.feedbacker_name')
    localStorage.removeItem('loopdesk.feedbacker_email')
  },

}