export default {
  '46fe1e24874ffc8a4054fd128ab6c072cf8fcdb500b6bac92d8b65d242208d28': {
    name: "Free",
    selectorsBlocked: ['content'],
    commentFileBlocked: true,
    feedbacksLimited: true,
  },
  'ff003571ccb9828570e783e0702af6057c47c46e21c49d38f1d6e70237a3dbf6': {
    name: "Freelancer",
    selectorsBlocked: ['content'],
    commentFileBlocked: true,
    feedbacksLimited: true,
  },
  '5b55cb445156d571574a6c91230b25b92d5b56315e682dd17d6c0cad98b4a26f': {
    name: "Agency",
    selectorsBlocked: null,
    commentFileBlocked: false,
    feedbacksLimited: false,
  },
  '4882402e8ef4690452b74a0b204b43d0a4a063cd7b24b211fa670818becb532a': {
    name: "Enterprise",
    selectorsBlocked: null,
    commentFileBlocked: false,
    feedbacksLimited: false,
  },
}