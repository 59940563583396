import FeedbackMarkerDot from "./FeedbackMarkers/FeedbackMarkerDot";
import FeedbackMarkerElement from "./FeedbackMarkers/FeedbackMarkerElement";
import FeedbackTextReplacement from "./FeedbackTextReplacement";
import FeedbackCard from "./FeedbackCard";
import {html} from "../../globals/constants";

export default {
  components: {FeedbackCard, FeedbackMarkerDot, FeedbackMarkerElement, FeedbackTextReplacement},
  template: `
<div class="ld-feedback-item" ref="feedbackItem" :class="[{ open: open, 'draft' : isDraft, 'hidden': !isInCurrentHref || hidden}, ...classList()]" 
        :data-ld-id="feedback.clientID">
  <component 
         v-if="marker && hasPosition" :feedback="feedback"
         :is="marker" :position="position" 
         :feedbackWindowWidth="feedbackWindowWidth" :currentWindowWidth="windowWidth" 
         @clicked="toggleFeedback">
    <FeedbackCard 
            :draft="isDraft" :feedback="feedback"  ref="feedbackCard" 
            @close="toggleFeedback" @destroy="destroyFeedback" />
  </component>
</div>
  `,
  props: {
    feedback: Object,
    forceClose: String,
    windowWidth: Number,
    hidden: Boolean,
  },
  watch: {
    forceClose(value) {
      this.closeFeedback()
    },
  },
  data() {
    return {
      markers: {
        'marker-dot': 'FeedbackMarkerDot',
        'marker-element': 'FeedbackMarkerElement',
      },
      open: false,
      overflow: {
        left: (x) => x + 400 > window.innerWidth ? 'align-right' : 'align-left',
        bottom: (y) => y + 400 > window.innerHeight + window.scrollY ? 'align-top' : 'align-bottom',
      },
      observerInstance: null,
      href: window.location.href,
    }
  },
  computed: {
    hasPosition() {
      // returns false if feedbackType requires an element in order to be positioned on dom
      if(this.feedback.isDraft) return true
      return !(this.feedback.feedbackType.position === "elementRelated" && !this.feedback.domElement.element);
    },
    isInCurrentHref() {
      return this.feedback.pageInfos.url === this.href.split('?')[0]
    },
    marker() {
      if (!this.feedback) return false
      const markerName = this.feedback.feedbackType.marker
      return markerName ? this.markers[markerName] || false : false
    },
    isDraft() {
      if (!this.feedback) return true
      return this.feedback.isDraft
    },
    position() {
      if (!this.feedback) return {x: 0, y: 0}
      return {x: this.feedback.position.pageX, y: this.feedback.position.pageY - 30,}
    },
    feedbackWindowWidth() {
      if (!this.feedback) return 0
      return this.feedback.position.windowWidth
    },
  },
  methods: {
    classList() {
      return [this.overflow.left(this.feedback.position.pageX), this.overflow.bottom(this.feedback.position.pageY)]
    },
    removeClickEventListener() {
      html.removeEventListener('click', this.checkClickPosition)
    },
    addClickEventListener() {
      html.addEventListener('click', this.checkClickPosition)
    },
    checkClickPosition(e) {
      const isChildOfCurrentFeedback = this.$refs.feedbackItem.contains(e.target)
      const isChildOfToolbar = document.querySelector('.ld-toolbar').contains(e.target)
      if (!this.feedback || isChildOfCurrentFeedback || isChildOfToolbar) return
      else {
        console.log("Toggle Feedback")
        this.toggleFeedback()
      }
    },
    destroyFeedback() {
      if (this.feedback.twinObserver) {
        this.feedback.twinObserver.stop()
      }
      this.$root.deleteFeedback(this.feedback.clientID)
    },
    toggleFeedback() {
      if (this.open) {
        if (this.feedback.isDraft && Object.values(this.feedback.edits).every(it => it === '')) this.destroyFeedback()
        this.closeFeedback()
      } else this.openFeedback()
    },
    closeFeedback() {
      this.open = false
      this.$emit('open', false)
      this.removeClickEventListener()
    },
    openFeedback() {
      this.open = true
      this.$emit('open')
      this.addClickEventListener()
    },
  },
  mounted() {
    if (this.isDraft) this.openFeedback()
    document.addEventListener("routeChange", () => this.href = window.location.href);
  }
}
