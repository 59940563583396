export default {

  key: null,

  setKey(key) {
    console.log(this.key = key)
  },

  getKey() {
    return this.key
  },

  fetchURL: (name) => "https://us-central1-loopdesk-dev.cloudfunctions.net/" + name,

  uploadFile: async function (formData, callback) {
    window.EventBus.trigger('startUploadFile')
    callback(true, "example")
  },

  updateFeedback: async function (data) {
    return new Promise(async (resolve, reject) => {
      resolve()
      // await fetch(this.fetchURL('editFeedback'), {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: data
      // })
      //   .then(res => {
      //     return res.json()
      //   })
      //   .then((body) => {
      //     window.EventBus.trigger('finishedUploadFeedback')
      //     if (body && body.success) {
      //       resolve(body)
      //     } else {
      //       reject(body.reason)
      //     }
      //   })
      //   .catch(err => {
      //     reject(err)
      //   })
    })
  },

  uploadFeedback: async function (data) {
    return new Promise(async (resolve, reject) => {

      resolve()

      // await fetch(this.fetchURL('addFeedback'), {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: data
      // })
      //   .then(res => {
      //     return res.json()
      //   })
      //   .then((body) => {
      //     console.log(body)
      //     window.EventBus.trigger('finishedUploadFeedback')
      //     if (body && body.success) {
      //       resolve(body)
      //     } else {
      //       reject(body.reason)
      //     }
      //   })
      //   .catch(err => {
      //     reject(err)
      //   })
    })
  },

  fetchFeedbacks: async function () {
    // if (window.EventBus) window.EventBus.trigger('startFetchFeedbacks')
    //
    // const key = this.key

    return new Promise(async (resolve, reject) => {
      resolve()
      // await fetch(`${this.fetchURL('getFeedbacks')}?key=${key}`, {
      //   method: 'GET',
      // })
      //   .then((res) => {
      //     console.log(res)
      //     window.EventBus.trigger('finishedFetchFeedbacks')
      //     if (res && res.status === 200) return res.json()
      //     else reject("Error for no reason")
      //   })
      //   .then((data) => {
      //     resolve(data)
      //   })
      //   .catch((err) => {
      //     reject(err)
      //   })
    })

  },


  // checkKey: async function (keys, success, fail) {
  //   return await fetch(this.fetchURL('checkKey'), {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       'url': window.location.href, //  window.location.href, // 'https://deeperly.ch/', // window.location.href
  //       'public_key': keys.public,
  //       'private_key': keys.private
  //     })
  //   })
  //     .then((res) => {
  //       if (res && res.status === 200) return res.json()
  //       else fail("Forbidden")
  //     })
  //     .then((body) => {
  //       if (body && typeof body === 'object') success(body)
  //       else fail("Forbidden")
  //     })
  //     .catch((error) => {
  //       fail(error)
  //     })
  // },


  executeCaptureFeedback: async function (html, queries) {

    // await fetch(`http://localhost:7000/?ww=${queries.ww}&wh=${queries.wh}&sx=${queries.sx}&sy=${queries.sy}&url=${queries.url}&capture_hash=${queries.hash}&ch=123456`, {
    fetch(`http://164.90.229.78:7000/?ww=${queries.ww}&wh=${queries.wh}&sx=${queries.sx}&sy=${queries.sy}&url=${queries.url}&ch=${queries.hash}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({html})
    })
      .then(res => {
        console.log(res)
        return res.json()
      })
      .then((body) => {
        console.log(body)
        console.log("Capture Fetched")
      })
      .catch(err => {
        console.log(err)
      })

  },

}