import FeedbackContentSet from "./FeedbackContentSet";
import FeedbackContentDraft from "./FeedbackContentDraft";
import Icon from "../Utilities/Icon";

export default {
  components: {FeedbackContentSet, FeedbackContentDraft, Icon},
  template: `
<div class="ld-feedback-card" :class="{ 'draft': isDraft }">
  <main class="ld-feedback-card-main">
    <FeedbackContentDraft :feedback="feedback" v-if="isDraft || isInEditMode" @feedbackUploaded="closeFeedback" @close="closeFeedback"/>
    <FeedbackContentSet v-else :feedback="feedback" @close="closeFeedback" @edit="openEditMode"/>
  </main>
</div>
  `,
  props: {
    feedback: Object,
  },
  data() {
    return {
      loadingForUpload: false,
      isInEditMode: false,
    }
  },
  computed: {
    isDraft() {
      return this.feedback ? this.feedback.isDraft : true
    }
  },
  methods: {
    closeFeedback() {
      this.$emit('close')
    },
    openEditMode() {
      console.log("TODO edit feedback mode")
      this.isInEditMode = true
    }
  },
  mounted() {
  }
}
