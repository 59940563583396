import Icon from "./Icon";
import firebase from "../../globals/services/firebase";

export default {
  components: {Icon},
  template: `
   <div class="ld-file-input-wrapper" :class="{'ld-file-upload-success': fileUpload.uploaded, 'ld-file-upload-loading': fileUpload.loading}">
      <label for="fileInputID"  class="ld-file-input-label">
        <span class="ld-label-title" v-if="label">{{ label }}</span>
        <div class="ld-file-input-overlay">
          <Icon icon="paperclip"/>
          <span>{{ fileInput || 'add file' }}</span>
        </div>
      </label>
      <input @change="uploadFile" type="file" id="fileInputID" name="fileInputID" accept="image/png, image/jpeg, .pdf" hidden>
   </div>
`,
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
  },
  data() {
    return {
      id: `ld-input-${this.type}-id-${Date.now()}`,
      fileInput: "",
      fileUpload: {
        loading: false,
        error: false,
        uploaded: false
      }
    }
  },
  watch: {
    'fileUpload.error'(value) {
      if (value) this.$emit('error', value)
    },
  },
  methods: {
    setInput(e) {
      this.$emit('setInput', e.target.value)
    },
    uploadFile(event) {
      this.fileUpload.error = "false"
      const fileInput = event.target
      const hasFiles = fileInput.files.length > 0
      const file = hasFiles ? fileInput.files[0] : null
      const filesize = file ? ((file.size / 1024) / 1024).toFixed(2) : 0; // MB

      if (!file) {
        this.fileUpload.error = "File upload: Please provide a file if you want to upload a file."
      } else if (filesize <= 1) {
        this.fileUpload.loading = true
        this.fileUpload.error = ""
        this.fileUpload.uploaded = false
        this.setFileInputText(file.name)
        this.uploadToFirebase(file)
      } else if (filesize > 1) {
        this.fileUpload.error = `The file size of your file is ${filesize}MB and exceeds the 1MB limit.`
      } else {
        this.fileUpload.error = "File upload: An error has occurred. Your file was not uploaded."
      }
    },
    async uploadToFirebase(file) {
      const formData = new FormData();
      formData.append('assets', file, file.name);
      await firebase.uploadFile(formData, this.uploadSuccess)
    },
    setFileInputText(name) {
      this.fileInput = name
    },
    uploadSuccess(success, filename) {
      this.fileUpload.loading = false
      if (success) {
        this.fileUpload.uploaded = true
        this.$emit('input', {value: filename, id: this.id, inputName: 'imageReplacement'})
      } else {
        this.fileUpload.error = "File upload: An error has occurred. Your file was not uploaded."
      }
    },
  },
  mounted() {
    this.$emit('setInput', this.placeholder)
  }
}