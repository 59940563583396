import firebase from "../../services/firebase";
import domElementToObject from "../Utils/domElementToObject";


export default class Feedback {
  constructor() {
    this.id = null  // this is is only set if feedback is initiated from existing feedback or after feedback upload
    this.clientID = this.generateRandomString(12) // this id is used only for the client
    this.created = Date.now()
    this.isDraft = true
    this.version = 1
    this.domElement = {
      path: null,
      content: null,
      element: null,
      tagName: null,
    }
    this.pageInfos = {
      url: null,
      params: null,
      pageTitle: null,
      useragent: null
    }
    this.capture = {
      url: "",
      base64String: "",
      domContent: "",
      hash: this.generateRandomString(25)
    }
    this.feedbackType = null
    this.feedbacker = {
      name: null,
      email: null,
      role: null,
      isCurrentUser: true,
    }
    this.edits = {
      comment: "",
      file: "",
      textReplacement: "",
      imageReplacement: "",
    }
    this.position = {
      pageX: 0,
      pageY: 0,
      windowWidth: 0,
      windowHeight: 0,
      scrollX: 0,
      scrollY: 0
    }
    this.category = false
  }

  initNewFeedback(x, y, windowWidth, windowHeight, scrollX, scrollY, feedbackType, elementPath) {
    this.setPosition(x, y, windowWidth, windowHeight, scrollX, scrollY)
    this.setRoute(window.location.href.split('?')[0], window.location.search)
    this.feedbackType = feedbackType
    this.domElement.path = elementPath
    this.version = 1
    this.setDomElementFromPath()
  }

  initFromExistingFeedback(obj, feedbackType, isCurrentUser) {
    console.log(obj.feedback_file)
    this.id = obj.id
    this.domElement.path = obj.dom_element_path
    this.capture.url = obj.capture_url
    this.isDraft = false
    this.feedbackType = feedbackType
    this.created = obj.created
    this.version = 1
    this.setPosition(obj.position_page_x, obj.position_page_y, obj.window_width, obj.window_height, obj.scroll_position_x, obj.scroll_position_y)
    this.setRoute(obj.url, obj.params)
    this.setEdits(obj.feedback_comment, obj.feedback_file, obj.feedback_text_replacement, obj.feedback_image_replacement)
    this.setFeedbacker(obj.feedback_username, obj.feedback_email, "", isCurrentUser)

    if (this.feedbackType.position === "elementRelated") {
      this.setDomElementFromPath()
    }
  }

  setDomElementFromPath() {
    const element = domElementToObject.decode(this.domElement.path, this.version)
    if (element) this.setElementValues(element)
  }

  setElementValues(element) {
    this.domElement.element = element
    this.domElement.tagName = element.tagName.toLowerCase()
    if (this.domElement.tagName === 'img') this.domElement.content = element.src
    else {
      this.domElement.content = []
      const childNodesArray = Array.from(element.childNodes)
      childNodesArray.forEach(({textContent}, index) => {
        if (textContent && textContent !== '') {
          this.domElement.content.push({
            childIndex: index,
            text: textContent
          })
        }
      })
    }
  }

  getInfoComponents() {
    return this.feedbackType.infoComponents
  }

  getDraftComponents() {
    return this.feedbackType.draftFormComponents
  }

  getDraftTitle() {
    return this.feedbackType.draftTitle
  }


  getFeedbackType() {
    return this.feedbackType
  }

  setRoute(url, params) {
    this.pageInfos = {
      url: url || "",
      params: params || "",
      pageTitle: document.title,
      useragent: navigator.userAgent
    }
  }

  setEdits(comment, file, textReplacement, imageReplacement) {
    this.edits = {
      comment,
      file,
      textReplacement,
      imageReplacement
    }
  }

  setFeedbacker(name, email, role, isCurrentUser) {
    this.feedbacker = {
      name,
      email,
      role,
      isCurrentUser,
    }
  }

  setPosition(pageX, pageY, windowWidth, windowHeight, scrollX, scrollY) {
    this.position = {
      pageX,
      pageY,
      windowWidth,
      windowHeight,
      scrollX,
      scrollY,
    }
  }


  generateRandomString(count = 12) {
    let ID = "";
    let characters = "0123456789";
    for (let i = 0; i < count; i++) ID += characters.charAt(Math.floor(Math.random() * 10));
    return ID;
  }

  async update(privateKey) {
    const data = {
      id: this.id,
      key: privateKey,
      feedback_comment: this.edits.comment,
      feedback_text_replacement: this.edits.textReplacement,
      feedback_image_replacement: this.edits.imageReplacement,
      feedback_file: this.edits.file,
      position_page_x: this.position.pageX,
      position_page_y: this.position.pageY,
      window_width: this.position.windowWidth,
      window_height: this.position.windowHeight,
      scroll_position_y: this.position.scrollY,
      scroll_position_x: this.position.scrollX,
    }

    return new Promise(async (resolve, reject) => {
      resolve()
    })
  }

  async upload() {
    return new Promise(async (resolve, reject) => {
      this.isDraft = false
      console.log(this)
      resolve()
    })
  }


  currentDocument() {
    const loopdeskSection = document.querySelector('section.ld-section')
    loopdeskSection.classList.add('ld-capture-mode')
    const documentHTML = document.documentElement.outerHTML
    loopdeskSection.classList.remove('ld-capture-mode')
    return documentHTML
  }


  getDataAsJSON() {
    return JSON.stringify({
      "key": "1234",
      "position_page_x": this.position.pageX,
      "position_page_y": this.position.pageY,
      "feedback_username": this.feedbacker.name,
      "feedback_email": this.feedbacker.email,
      "params": this.pageInfos.params,
      "scroll_position_x": this.position.scrollX,
      "scroll_position_y": this.position.scrollY,
      "page_title": this.pageInfos.pageTitle,
      "feedback_type": this.feedbackType.id,
      "url": this.pageInfos.url,
      "user_agent": this.pageInfos.useragent,
      "window_width": this.position.windowWidth,
      "window_height": this.position.windowHeight,
      "dom_element_path": this.domElement.path,
      "dom_element_content": this.domElement.content,
      "document_html": "null",
      "v": this.version,
      "feedback_comment": this.edits.comment,
      "feedback_text_replacement": this.edits.textReplacement,
      "feedback_image_replacement": this.edits.imageReplacement,
      "feedback_file": this.edits.file,
      "capture_url": this.capture.base64String || "null",
      "capture_hash": this.capture.hash || "null",
      "category": this.category ? [this.category] : "null",
    })
  }
}

