import LoadingSpinner from "../Utilities/LoadingSpinner";
import InputTextarea from "../Utilities/InputTextarea";
import InputFile from "../Utilities/InputFile";
import InputDropdown from "../Utilities/InputDropdown";
import ActionArea from "../Utilities/ActionArea";
import InputTextEditable from "../Utilities/InputTextEditable";

export default {
  components: {InputTextEditable, InputTextarea, InputFile, InputDropdown, LoadingSpinner, ActionArea},
  template: `
  <form class="ld-feedback-card-form" :class="{'frozen': upload.loading }" ref="feedbackForm" action="javascript:void(0);">
    <p class="ld-draft-title">{{ formTitle }}</p>
    <fieldset>
        <InputFile v-if="formComponents.contentReplacement && feedbackDomImageElement" label="Suggest new image" @setInput="setInput($event, 'imageReplacement')" @error="handleError"/>
        <InputTextEditable v-else-if="formComponents.contentReplacement" label="Content suggestion" :domElement="feedback.domElement" @setInput="setInput($event, 'textReplacement')"/>
        <InputTextarea v-if="formComponents.comment" :label="formComponents.comment" placeholder="type here" :fileAttachment="true" @setInput="setInput($event, 'comment')" @setFileInput="setInput($event, 'file')" @error="handleError"/>
        <InputDropdown v-if="formComponents.category" :label="formComponents.category" :options="categories" @setInput="setFeedbackCategory"/>
    </fieldset>

    <div v-if="errors" class="ld-feedback-form-error">
        <p v-if="errors.file">{{ errors.file }}</p>
        <p v-if="errors.upload">{{ errors.upload }}</p>
    </div>
    
    <ActionArea 
        :actions="[ !hasInputSet ? 'Cancel' : 'Close Feedback', 'Save feedback']"
        :disabledAction="!hasInputSet || upload.error"
        @action="submitForm" @actionReturn="closeFeedback"
    />
  
    <LoadingSpinner v-if="upload.loading"/>
  </form>
  `,
  props: {
    feedback: Object,
  },
  data() {
    return {
      categories: ['UI', 'Frontend', 'Backend', 'Content', 'Bug', 'Feature Request', 'Suggestion', 'Integration', 'Testing', 'Hotfix', 'Improvement'],
      hasInputSet: false,
      upload: {
        submitted: false,
        error: false,
        loading: false,
      },
      formTitle: this.feedback.getDraftTitle(),
      formComponents: this.feedback.getDraftComponents(),
      feedbackDomImageElement: this.feedback.domElement.tagName === 'img',
      errors: {
        file: "",
        upload: ""
      }
    }
  },
  methods: {
    submitForm() {
      this.handleSubmit()
    },
    closeFeedback() {
      this.$emit('close')
    },
    displayError(error) {
      this.errors[error.id] = error.value
    },
    setFeedbackCategory(value) {
      this.feedback.category = value
    },
    setInput(value, inputName) {
      if (inputName) this.feedback.edits[inputName] = value || ""
      this.hasInputSet = Object.values(this.feedback.edits).some(it => it !== '')
    },
    handleSubmit() {
      if (!this.hasInputSet) return false
      this.uploadFeedback()
    },
    handleError(error) {
      this.errors.file = error
    },
    async uploadFeedback() {
      this.errors.upload = ""
      this.upload.submitted = false
      this.errors.upload = false
      this.upload.loading = true
      await this.feedback.upload()
        .then(({newFeedbackCount}) => {
          this.$emit('feedbackUploaded')
          this.upload.loading = false
          this.upload.submitted = true
        })
        .catch((error) => {
          this.$emit('feedbackUploaded')
          this.upload.loading = false
          this.upload.submitted = true
        })
    },
  },
  mounted() {
  }
}
