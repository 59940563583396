const CryptoJS = require("crypto-js");

export default class User {
  constructor() {
    this.name = 'you'
    this.email = 'you@loopdesk.io'
    this.role = 'User'
    this.saveUser()
  }


  update(name, email, role) {
    this.name = 'you'
    this.email = 'you@loopdesk.io'
    this.role = 'User'
  }

  saveUser() {
    this.saveInLocalstorage()
  }

  encrypt(string) {
    if (string) return CryptoJS.AES.encrypt(string, 'somesecretkey').toString()
    return ""
  }

  decrypt(string) {
    if (string) return CryptoJS.AES.decrypt(string, 'somesecretkey').toString(CryptoJS.enc.Utf8)
    return false
  }

  saveInLocalstorage() {
    localStorage.setItem('loopdesk.feedbacker_email', this.encrypt(this.email))
    localStorage.setItem('loopdesk.feedbacker_username', this.encrypt(this.name))
    localStorage.setItem('loopdesk.feedbacker_role', this.encrypt(this.role))
  }

  remove() {
    localStorage.removeItem('loopdesk.feedbacker_email')
    localStorage.removeItem('loopdesk.feedbacker_username')
    localStorage.removeItem('loopdesk.feedbacker_role')
  }

  isValid() {
    return this.name && this.email
  }


}