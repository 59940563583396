import Project from "../constructors/Project/Project";
import sourceOfTruth from "./../../state-management"
import firebase from "./firebase";

const CryptoJS = require("crypto-js");


export default {

  initKey: function () {
    this.privateKey = this.getPrivateKey()
    this.publicKey = this.getPublicKey()
  },

  check: async function () {
    this.initKey()

    return new Promise(async (resolve, reject) => {

      const projectInstance = new Project(this.defaultProject.loop, this.defaultProject.plan, this.privateKey)
      projectInstance.init()
        .then(() => {
          sourceOfTruth.setProject(projectInstance)
          resolve()
        })
        .catch((err) => {
          reject("rejecting for no reason")
        })

    })
  },

  getError(error) {
    console.log(error)
  },


  defaultProject: {
    "msg": "allowed",
    "project_color": "#00000",
    "loop": {
      "name": "loop-name xy",
      "key": "loop-key"
    },
    "plan": {
      "id": "4882402e8ef4690452b74a0b204b43d0a4a063cd7b24b211fa670818becb532a",
      //"id": "sdfsdf",
      "uploadedCount": 10,
      "maximalCount": 100
    }
  },

  encrypt: (string) => {
    if (string) return CryptoJS.AES.encrypt(string, 'somesecretkey').toString()
    return ""
  },

  decrypt: (string) => {
    if (string) return CryptoJS.AES.decrypt(string, 'somesecretkey').toString(CryptoJS.enc.Utf8)
    return false
  },

  getPrivateKey: function () {
    const storedLocalKey = localStorage.getItem('loopdesk.key') || null
    const localKey = storedLocalKey ? this.decrypt(storedLocalKey) : null

    const urlKey = this.getQueries().loopdesk || null
    if (!localKey && urlKey) {
      localStorage.setItem('loopdesk.key', this.encrypt(urlKey))
    }
    if (urlKey && 'URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("loopdesk");
      window.location.search = searchParams.toString();
    }
    return urlKey || localKey || false
  },

  getPublicKey: function () {
    const script = document.querySelector("SCRIPT[loopdesk]")
    return script ? script.getAttribute('loopdesk') || false : false
  },

  getQueries: function () {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
  },


  privateKey: false,
  publicKey: false,


}

