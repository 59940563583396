import Icon from "../../Utilities/Icon";

export default {
  components: {Icon},
  template: `
<div class="ld-toolbar-block">
    <p class="block-title hidden-mobile">{{ title }}</p>
    <div class="ld-selectors">
      <div class="ld-selector-wrapper" v-for="type in feedbackTypes" :key="type.id" :class="{'disabled': maxFeedbacks - usedFeedbacks <= 0 }">
        <button @click="setSelector(type.id)" :class="{ 'selected': activeFeedbackMode === type.id }">
            <Icon :icon="type.icon" />
        </button>
        <div class="ld-selector-info">
          <p class="ld-title"><b>{{ type.title }}</b></p>
          <p class="ld-description">{{ type.description }}</p>
        </div>
      </div>  
    </div>
</div>
  `,
  data() {
    return {
      title: "Add feedback",
      feedbackTypes: this.$root.getFeedbackTypes(),
      project: this.$root.getProject(),
    }
  },
  computed: {
    usedFeedbacks() {
      return this.project.plan.feedbackCount.current
    },
    maxFeedbacks() {
      return this.project.plan.feedbackCount.max
    },
    activeFeedbackMode(){
      return this.$root.getActiveFeedbackMode()
    }
  },
  methods: {
    setSelector(id) {
      this.$root.setFeedbackMode(id)
      this.$emit('closeToolbar')
    }
  },
}
