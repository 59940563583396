import TwinObserver from "../../../globals/constructors/Utils/TwinObserver";
import LoadingSpinner from "../../Utilities/LoadingSpinner";

export default {
  components: {LoadingSpinner},
  template: `
<div class="ld-feedback-wrapper" :class="['ld-id-' + feedback.clientID, {'draft': draft}]" ref="feedbackWrapper">
  <div class="ld-feedback-marker-element" @click="$emit('clicked')" ref="marker" :class="{'hidden': !observer.initialized || isMobile }">
    <div class="ld-feedback-marker-title">{{ name }}</div>
  </div>
  <div class="ld-slot-wrapper" ref="slotWrapper" :style="{top: top +'px' , right: right +'px'}" :class="{'hidden': !observer.initialized}">  
    <slot ref="card"></slot>
  </div>
  <div v-if="!observer.initialized" :style="{ top: feedback.position.pageY +'px' , left: feedback.position.pageX +'px'}">
    <LoadingSpinner />
  </div>
</div>
  `,
  props: {
    draft: Boolean,
    feedback: Object
  },
  computed: {
    isMobile() {
      return this.$root.getMobileView()
    },
  },
  data() {
    return {
      name: this.feedback.feedbacker.name,
      element: null,
      alignmentY: 'bottom' || 'top',
      alignmentX: 'left' || 'right',
      right: 0,
      top: 0,
      observer: {
        initialized: false,
        error: false
      }
    }
  },
  methods: {
    alignSlotWrapper() {
      const feedbackCard = document.querySelector(`.ld-id-${this.feedback.clientID} .ld-feedback-card`)
      this.top = feedbackCard.clientHeight * -1 - 100
      this.right = 0
    },
    addTwinObserver() {
      this.feedback.twinObserver = new TwinObserver(this.feedback.domElement.element, this.$refs.feedbackWrapper, this.feedback.clientID)
      this.feedback.twinObserver.init()
        .then(() => {
          this.observer.initialized = true
          this.alignSlotWrapper()
        })
        .catch(() => this.observer.error = true)
    },
  },
  mounted() {
    const timeout = this.draft ? 0 : 500
    setTimeout(() => {
      if (this.isMobile) {
        this.observer.initialized = true
      } else {
        this.addTwinObserver()
      }
    }, timeout);
  }
}
