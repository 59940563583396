import Icon from "./Icon";
import InputFile from "./InputFile";

export default {
  components: {Icon, InputFile},
  template: `
   <div class="ld-textarea-wrapper">
       <label :for="id">{{label}}</label>
        <div class="ld-input-text-replacement" ref="inputContent">
            <span :id="id" contenteditable v-for="(item, index) in content" @input="editHTML(index, item.childIndex)" :item-id="item.index">{{ item.text }}</span>
        </div>
   </div>
`,
  props: {
    domElement: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Replace Text'
    }
  },
  data() {
    return {
      id: `ld-text-replacement-id-${Date.now()}`,
      contentInputs: [],
      element: this.domElement.element,
      content: this.domElement.content,
    }
  },
  methods: {
    setInput(e) {
      this.$emit('setInput', e.target.value)
    },
    editHTML(spanIndex, childIndex) {
      const childElement = this.element.childNodes.item(childIndex)
      const spanElement = this.$refs.inputContent.childNodes.item(spanIndex)
      childElement.textContent = spanElement.textContent

      if (spanElement.textContent === this.content[spanIndex].text) {
        // that means original value is same are edited value
        this.contentInputs[childIndex] = null
      } else {
        // this means, original value was edited
        this.contentInputs[childIndex] = spanElement.textContent
      }

      this.emitTextReplacement()
    },
    emitTextReplacement() {
      const changesMade = this.contentInputs.some(it => it)
      const value = changesMade ? JSON.stringify(this.contentInputs) : ""
      this.$emit('setInput', value)
    },
  },
  mounted() {
    this.$emit('setInput', this.initialValue)
  }
}