import ToolbarFooter from "./Toolbar/ToolbarFooter";
import ToolbarHeader from "./Toolbar/ToolbarHeader";
import ToolbarMain from "./Toolbar/ToolbarMain";
import Toggle from "./Utilities/Toggle";

export default {
  components: {ToolbarFooter, ToolbarHeader, ToolbarMain, Toggle},
  template: `
<article class="ld-toolbar" :class="{ expanded }">
  <ToolbarHeader @leaveLoopdesk="leaveLoopdesk" @minimizeLoopdesk="minimizeLoopdesk"/>
  <ToolbarMain />
  <ToolbarFooter :expanded="expanded"/>
</article>
  `,
  data() {
    return {
      expanded: false,
      feedbacksHidden: false,
    }
  },
  computed: {},
  methods: {
    leaveLoopdesk() {
      this.$emit('leave')
    },
    minimizeLoopdesk() {
      this.$emit('minimize')
    },
    toggleExpanded() {
      this.expanded = !this.expanded
    },
  },
  mounted() {
  }
}
