import ActionArea from "../Utilities/ActionArea";
import FeedbackInfo from "./FeedbackInfo";

export default {
  components: {ActionArea, FeedbackInfo},
  template: `
<div class="ld-feedback-card-set" :class="{editable}">  
  <div class="ld-feedback-comment">
     <p v-if="feedback.edits.comment"><b>Comment: </b>{{ feedback.edits.comment }}</p>
     <p v-if="feedback.edits.file">A file was uploaded to supplement the comment. </p>
     <p v-if="feedback.edits.textReplacement"><b>Text suggestion: </b>{{ getTextReplacement() }}</p>
     <p v-if="feedback.edits.imageReplacement"><b>New image suggestion. </p>
  </div>
  
  <FeedbackInfo :feedback="feedback" />
  
  <ActionArea 
      :actions="[ 'Close Feedback', editable ? 'Edit feedback' : null ]"
      @action="openEditFeedback" @actionReturn="closeFeedback"
  />
</div>
  `,
  props: {
    feedback: Object,
  },
  data() {
    return {
      // editable: this.feedback.feedbacker.isCurrentUser
      editable: false
    }
  },
  computed: {},
  methods: {
    getTextReplacement() {
      if (!this.feedback.edits || !this.feedback.edits.textReplacement || !this.feedback.domElement.content) return false
      const edits = this.feedback.edits.textReplacement
        .substring(1, this.feedback.edits.textReplacement.length - 1)
        .split(",")

      return this.feedback.domElement.content
        .map((item) => {
          const edit = edits[item.childIndex]
          if (edit && edit !== 'null') return edits[item.childIndex].substring(1, edit.length - 1)
          return item.text
        })
        .join(" ")
    },
    closeFeedback() {
      this.$emit('close')
    },
    openEditFeedback() {
      console.log("TODO: Open Edit Mode")
      this.$emit('edit')
    }
  },
  mounted() {
    this.getTextReplacement()
  }
}
