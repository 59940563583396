import Icon from "./Utilities/Icon";

export default {
  components: {Icon},
  template: `
<article class="ld-launch"> 
<!--  <button class="ld-leave-loopdesk" @click="requestLeaveLoopdesk"><Icon icon="btn-close"/></button>-->
  <Icon icon="logo-vertical" :classList="['ld-logo']"/>
  <Icon icon="launch-button" @click.native="launch" :classList="['ld-launch-icon']"/>
  <button class="ld-leave-loopdesk" @click="leave"><em>Leave Loopdesk</em><b> Sure you want to leave Loopdesk?</b></button>
</article>
  `,
  data() {
    return {}
  },
  computed: {},
  methods: {
    launch() {
      window.EventBus.trigger('launchLoopdesk')
    },
    leave(){
      window.EventBus.trigger('leaveLoopdesk')
    }
  },
  mounted() {

  }
}
